<template>
    <section
        :id="$options.name"
        class="order-form-step price-calculator"
    >
        <header class="order-form-step__heading">
            <h1
                class="order-form-step__heading"
                :class="{'order-form-step__heading--closed': activeStep !== $options.name }"
            >
                <span class="order-form-step__step-number">Step 1.</span>
                Price calculator
            </h1>
            <CheckCircle
                v-if="isFormValid"
                class="order-form-step__confirm-icon"
            />
        </header>
        <section
            v-if="activeStep === $options.name && !isLoading"
            id="move_asigment"
            class="price-calculator__form"
        >
            <!-- link to order component : begin -->
            <div>
                <!-- show this component to logged in users who have more than one previous order -->
                <div class="order-form-step__column row attach">
                    <form-switch
                        :id="'chk_linked'"
                        v-model="chk_linked"
                    />
                    <span class="order-form-step__switch-text">
                        I want to attach this order to an existing project
                    </span>
                </div>
                <div v-show="chk_linked">
                    <div class="order-form-step__column">
                        <form-select-project
                            v-model="$v.linked_to_order.$model"
                            label="Pretype order ID or choose from the list"
                            :errors="validationMessage($v.linked_to_order)"
                            name="linked_to_order"
                            @change.native="$v.linked_to_order.$touch()"
                            @blur.native="$v.linked_to_order.$touch()"
                        />
                    </div>
                </div>
            </div>
            <!-- link to order component : end -->
            <!-- calculator, project type and academic level -->
            <div class="row space-between">
                <!-- Type -->
                <div
                    class="order-form-step__column order-form-step__column--half order-form-step__column-type"
                >
                    <form-select
                        v-model="$v.type.$model"
                        label="Project type"
                        prepend-icon="FileDocumentIcon"
                        :value-select="$v.type.$model"
                        :items="getterTypes"
                        name="type"
                        :readonly="isMobile"
                        :errors="validationMessage($v.type)"
                        @change.native="$v.type.$touch()"
                        @blur.native="$v.type.$touch()"
                    />
                    <span
                        class="custom-tooltip"
                    >
                        <p>?</p>
                        <span>Select the type of project that most closely describes your assignment (i.e. do not choose essay, if you have a complex research paper or report).</span>
                    </span>
                </div>
                <!-- Academiclevel -->
                <div
                    class="order-form-step__column order-form-step__column--half"
                >
                    <form-select
                        v-model="$v.academiclevel.$model"
                        prepend-icon="BookOpenVariantIcon"
                        :items="getterLevels"
                        :value-select="$v.academiclevel.$model"
                        label="Academic level"
                        tabindex="0"
                        name="level"
                        :readonly="isMobile"
                        :errors="validationMessage($v.academiclevel)"
                        @change.native="$v.academiclevel.$touch()"
                        @blur.native="$v.academiclevel.$touch()"
                    />
                    <span class="custom-tooltip">
                        <p>?</p>
                        <span>Our writers will try to match the requested level through appropriate word choice, grammar style, and sentence structure.</span>
                    </span>
                </div>
            </div>
            <!-- calculator, project type and academic level -->

            <div class="row row--three-item space-between">
                <!-- pages -->
                <div
                    class="order-form-step__column order-form-step__column--half price-calculator__item-order price-calculator__item-order--1"
                >
                    <form-input-type-number
                        v-model="$v.pages.$model"
                        label="Pages"
                        :field.sync="pages"
                        :show_value="true"
                        tabindex="0"
                        :min_value="0"
                        :errors="validationMessage($v.pages)"
                        @reduce="reduceCount('pages')"
                        @increase="increaseCount('pages')"
                        @update:field="$v.pages.$touch()"
                    />
                    <span class="custom-tooltip">
                        <p>?</p>
                        <span>All content is formatted as follows: 12 point Times New Roman font, 1 inch margins on all sides, double spacing, approx. 280 - 300 words per page.</span>
                    </span>
                </div>
                <!-- due_at -->
                <div
                    class="order-form-step__column order-form-step__column--half order-form-step__column--half price-calculator__item-order price-calculator__item-order--2"
                >
                    <form-date-picker
                        v-model="$v.due_at.$model"
                        label="Due date"
                        tabindex="0"
                        :min_hours="3"
                        :errors="validationMessage($v.due_at)"
                        :field.sync="due_at"
                        @change.native="$v.due_at.$touch()"
                        @blur.native="$v.due_at.$touch()"
                    />
                    <span class="custom-tooltip">
                        <p>?</p>
                        <span>
                            Deadline is in your local timezone.<br><br> Please be realistic about the deadline and spare some time to
                            have it revised by the writer.<br><br>
                            Our writers need a minimum of 30 minutes to type 1 page and the research takes time too.<br><br>
                            To avoid disappointment, we suggest that you think of the deadline date that you specify in this order form as
                            the moment you will receive your first draft rather than the date/time your project is due.<br><br>

                            While we do our best to meet all initial instructions
                            with the first draft, there are times when a rewrite is inevitable and the writers need time to address your concerns.
                            So please choose the deadline wisely so that you have sufficient time to review the draft and have the writer revise,
                            if needed.
                        </span>
                    </span>
                </div>
                <!-- pages -->
                <div
                    v-if="pages > 0"
                    class="order-form-step__column order-form-step__column--half order-form-step__column--half price-calculator__item-order price-calculator__item-order--3"
                >
                    <div class="order-form-step__word-count">
                        <!-- number filter is needed to separate thousands -->
                        {{ pages }} double spaced
                        <span v-if="pages > 1">pages</span>
                        <span v-else>page</span>
                        =  &#126; {{ pages * wordcount | number }} words
                    </div>
                </div>
                <!-- due_at -->
                <div
                    v-if="due_at"
                    class="order-form-step__column order-form-step__column--half order-form-step__column--half price-calculator__item-order price-calculator__item-order--4"
                >
                    <div class="order-form-step__word-count">
                        {{ due_at | moment_from }} to deadline
                    </div>
                </div>
            </div>

            <!-- slides -->
            <div class="switch-box">
                <div class="order-form-step__switch-row">
                    <form-switch
                        :id="'chk_slides'"
                        v-model="chk_slides"
                    />
                    <span class="order-form-step__switch-text">
                        Presentation slides (PowerPoint, etc.)
                    </span>
                </div>
                <div
                    v-if="chk_slides"
                    class="order-form-step__column order-form-step__column--half"
                >
                    <form-input-type-number
                        v-model="$v.slides.$model"
                        label="Slides"
                        :value="slides"
                        :field.sync="slides"
                        :show_value="true"
                        :min_value="1"
                        :init_value="1"
                        tabindex="0"
                        :errors="validationMessage($v.slides)"
                        @reduce="reduceCount('slides')"
                        @increase="increaseCount('slides')"
                        @change.native="$v.slides.$touch()"
                        @blur="$v.slides.$touch()"
                    />
                </div>
            </div>

            <!-- charts -->
            <div class="switch-box">
                <div class="order-form-step__switch-row">
                    <form-switch
                        :id="'chk_charts'"
                        v-model="chk_charts"
                        messages
                    />
                    <span class="order-form-step__switch-text">
                        Charts (tables, graphs, etc.)
                    </span>
                </div>
                <div
                    v-if="chk_charts"
                    class="order-form-step__column order-form-step__column--half"
                >
                    <form-input-type-number
                        v-model="$v.charts.$model"
                        label="Charts"
                        :value="charts"
                        :field.sync="charts"
                        :show_value="true"
                        :min_value="1"
                        :init_value="1"
                        tabindex="0"
                        :errors="validationMessage($v.charts)"
                        @reduce="reduceCount('charts')"
                        @increase="increaseCount('charts')"
                        @change="$v.charts.$touch()"
                        @blur="$v.charts.$touch()"
                    />
                </div>
            </div>

            <!-- problems -->
            <div class="switch-box">
                <div class="order-form-step__switch-row">
                    <form-switch
                        :id="'chk_problems'"
                        v-model="chk_problems"
                    />
                    <span class="order-form-step__switch-text">
                        Quantitative problems (accounting, finance,
                        statistics, algebra, calculus etc.)
                    </span>
                </div>
                <div
                    v-if="chk_problems"
                    class="order-form-step__column order-form-step__column--half"
                >
                    <form-input-type-number
                        v-model="$v.problems.$model"
                        label="Problems"
                        :value="problems"
                        :field.sync="problems"
                        :show_value="true"
                        :min_value="1"
                        :init_value="1"
                        tabindex="0"
                        :errors="validationMessage($v.problems)"
                        @reduce="reduceCount('problems')"
                        @increase="increaseCount('problems')"
                        @change="$v.problems.$touch()"
                        @blur="$v.problems.$touch()"
                    />
                </div>
            </div>

            <!-- discount -->
            <!-- <div>
                <div class="order-form-step__switch-row">
                    <form-switch
                        :id="'chk_discount'"
                        v-model="chk_discount"
                    />
                    <span class="order-form-step__switch-text">
                        I have a special discount code
                    </span>
                </div>
                <div
                    v-if="chk_discount"
                    class="order-form-step__column order-form-step__column--half order-form-step__column-discount"
                >
                    <form-input
                        v-model="discountEntry"
                        legend="Discount code"
                        type="text"
                        name="discount_code"
                        :field.sync="discountEntry"
                        :is-valid="$v.discount_code.$dirty && !$v.discount_code.$anyError"
                        :errors="validationMessage($v.discount_code)"
                        @input.native="$v.discount_code.$touch();"
                        @blur.native="$v.discount_code.$touch();"
                    />
                </div>
            </div> -->

            <button-controls
                :settings="buttonSettings"
                :is-form-valid="isFormValid && !isCalculating"
                class="order-form-step__buttons_price_calculator"
                @next="prevalidate()"
            />
        </section>
    </section>
</template>

<script>
// import { debounce } from 'lodash'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import CheckCircle from 'mdi-vue/CheckCircle.vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import moment from 'moment'
import { eventBus } from '@/helpers/event-bus';
import { mobileCheck } from '@/helpers/deviceChecker'

// components
import ButtonControls from '@/components/checkout/ButtonControls.vue'

// validation
import formValidationMixin from '@/mixins/form-validation-mixin.js'
import { validationRules } from '@/validation/order/rules-price-calculator.js'
import { formMessages } from '@/validation/order/messages-price-calculator.js'

// Mixins
import filtersMixin from '@/mixins/filters-mixin.js'

// vuex
import { mapOrderFormFields } from '@/store/modules/order'
import {
    ACTION_VALIDATE_DISCOUNT,
    ACTION_EDIT_ORDER
} from '@/store/modules/order/action-types.js'

const {
    mapActions: mapOrderActions
} = createNamespacedHelpers('order')

export default {
    name: 'PriceCalculator',
    components: {
        ButtonControls,
        CheckCircle
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        filtersMixin
    ],
    validations: {
        ...validationRules
    },
    props: {
        isCalculating: {
            type: Boolean,
            required: true
        },
        activeStep: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            wordcount: 280,
            // discountEntry: '',
            isLoading: true,
            isMobile: false
        }
    },
    computed: {
        ...mapOrderFormFields([
            'form_data.chk_linked',
            'form_data.linked_to_order',
            'form_data.type',
            'form_data.academiclevel',
            'form_data.pages',
            'form_data.due_at',
            'form_data.chk_slides',
            'form_data.slides',
            'form_data.chk_charts',
            'form_data.charts',
            'form_data.chk_problems',
            'form_data.problems'
            // 'form_data.chk_discount',
            // 'form_data.discount_code'
        ]),
        ...mapGetters('config', [
            'getterTypes',
            'getterLevels'
        ]),
        isFormValid() {
            return !this.$v.$invalid
        },
        buttonSettings() {
            return {
                next: {
                    active: true,
                    loading: false
                }
            }
        }
    },
    watch: {
        // discountEntry: debounce(function (value) {
        //     this.discount_code = value
        // }, 1000),
        chk_slides(val) {
            if (!val) {
                this.slides = 0;
            }
        },
        chk_charts(val) {
            if (!val) {
                this.charts = 0;
            }
        },
        chk_problems(val) {
            if (!val) {
                this.problems = 0;
            }
        }
        // chk_linked(val) {
        //     if (!val) {
        //         this.linked_to_order = '';
        //     }
        // }
        // chk_discount(val) {
        //     if (!val) {
        //         this.discountEntry = '';
        //         this.discount_code = '';
        //     }
        // }
    },
    async created() {
        this.isLoading = true
        const { linked_to_order } = this.$route.query
        if (linked_to_order) {
            this.chk_linked = true
            this.linked_to_order = linked_to_order
        }
        // set discount entry to a value stored in vuex
        this.discountEntry = this.discount_code
        // this is needed to reset the vuelidate validation model on form reset
        eventBus.$on('reset-form', () => {
            this.$v.$reset()
        })
        this.getCalculatorDataFromQuery()
        if (this.due_at) {
            this.$v.due_at.$touch()
        }
        // if (this.discount_code) {
        //     this.$v.discount_code.$touch()
        // }
        if (this.pages === 0) {
            this.$v.pages.$touch()
        }
        this.isLoading = false
    },
    mounted() {
        this.isMobile = mobileCheck()
    },
    methods: {
        ...mapOrderActions({
            validateDiscount: ACTION_VALIDATE_DISCOUNT,
            ACTION_EDIT_ORDER
        }),
        validationMessage: validationMessage(formMessages),
        prevalidate() {
            this.validate()
        },
        submit() {
            this.$emit('setNextStep', this.$options.name)
        },
        getCalculatorDataFromQuery() {
            const {
                type, pages, academiclevel, date
            } = this.$route.query
            if (type) {
                this.type = type
                this.pages = pages
                this.academiclevel = academiclevel
                this.due_at = date
                if (date.length < 4) {
                    this.due_at = moment().add(Number(date) + 1, 'hours').toISOString()
                }
            }
        },
        reduceCount(type) {
            this[type] -= 1
            this.$v[type].$touch()
        },
        increaseCount(type) {
            this[type] += 1
            this.$v[type].$touch()
        }
    }
}
</script>
