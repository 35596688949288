<template>
    <div class="order-form wrapper">
        <breadcrumbs
            :settings="settingsBreadcrumbs"
            back-btn-path="/order"
        />
        <RadiusLoader v-if="configLoading" />
        <div
            v-else
            class="row order-form-wrapper"
        >
            <!-- left column -->
            <div class="order-form__left-column">
                <price-calculator
                    ref="PriceCalculator"
                    :active-step="getterActiveStep"
                    :is-calculating="isCalculating"
                    @setNextStep="onSetNextStep"
                    @setPreviousStep="onSetPreviousStep"
                />
                <assignment-details
                    ref="AssignmentDetails"
                    :active-step="getterActiveStep"
                    :is-calculating="isCalculating"
                    @setNextStep="onSetNextStep"
                    @setPreviousStep="onSetPreviousStep"
                />
                <extras-services
                    ref="ExtrasServices"
                    :active-step="getterActiveStep"
                    :is-calculating="isCalculating"
                    @setNextStep="onSetNextStep"
                    @setPreviousStep="onSetPreviousStep"
                />
            </div>
            <!-- left column-->

            <!-- right column - quote id="sidebar"-->
            <div class="order-form__right-column">
                <div class="sticky">
                    <quote-sidebar
                        :is-edit-page="isEditPage"
                        :submit-order="submitOrder"
                        @eventSubmitOrder="onEventSubmitOrder"
                        @eventClearForm="onEventClearForm"
                        @eventValidateForm="onEventValidateForm"
                        @eventCalculating="onEventCalculating"
                    />
                </div>
            </div>
            <!-- right column - quote -->
        </div>
    </div>
</template>

<script>
import { scroller } from 'vue-scrollto/src/scrollTo'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { debounce } from 'lodash'

import { eventBus } from '@/helpers/event-bus/'

// components
import RadiusLoader from '@/components/common/RadiusLoader.vue'
import PriceCalculator from '@/components/order/PriceCalculator.vue';
import AssignmentDetails from '@/components/order/AssignmentDetails.vue';
import QuoteSidebar from '@/components/order/QuoteSidebar.vue'
import Breadcrumbs from '@/components/Breadcrumbs';

// vuex bindings
import {
    CLEAR_FORM_STANDARD,
    LOADING_SET_STATUS,
    CLEAR_FILES_TO_UPLOAD,
    CLEAR_ALL_FILES
} from '@/store/modules/order/mutation-types'
import {
    ACTION_NEW_ORDER,
    ACTION_EDIT_ORDER,
    ACTION_SET_ACTIVE_STEP,
    ACTION_SET_FIRST_ORDER_STEP
} from '@/store/modules/order/action-types'
import {
    GET_PRICING
} from '@/store/modules/config/action-types'
import { mapOrderFormFields } from '@/store/modules/order'

import { orderLog, orderLogChangeSteps } from '@/services/order-log'
import ExtrasServices from '@/components/order/ExtrasServices.vue';

const {
    mapMutations: mapOrderMutations,
    mapActions: mapOrderActions
} = createNamespacedHelpers('order')
const {
    mapActions: mapConfigActions
} = createNamespacedHelpers('config')

export default {
    name: 'OrderForm',
    components: {
        PriceCalculator,
        AssignmentDetails,
        ExtrasServices,
        QuoteSidebar,
        Breadcrumbs,
        RadiusLoader
    },
    metaInfo: {
        title: 'New Order'
    },
    data: () => ({
        validateForm: false,
        isCalculating: true,
        configLoading: true,
        submitOrder: false
    }),
    computed: {
        ...mapGetters('order', [
            'getterActiveStep'
        ]),
        ...mapOrderFormFields([
            'form_data.discount_code',
            'form_data.preferred_writers',
            'form_data.blocked_writers',
            'form_data'
        ]),
        isEditPage() {
            return Boolean(this.$route.params.orderid)
        },
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: breadcrumbs.name,
                excerpt: breadcrumbs.excerpt,
                additionalClass: breadcrumbs.additionalClass
            }
        }
    },
    watch: {
        getterActiveStep() {
            this.$nextTick(() => {
                this.scrollToBlock()
            })
        },
        form_data: {
            handler: debounce(function (value) {
                orderLog(value, this.$route.params.orderid);
            }, 500),
            deep: true
        }
    },
    async created() {
        this[LOADING_SET_STATUS]({ message: '', status: false })
        this.configLoading = true
        if (this.$route.name === 'order') {
            this[CLEAR_ALL_FILES]()
        }
        if (this.$route.params.orderid) {
            await this[ACTION_EDIT_ORDER]({ orderid: this.$route.params.orderid })
        }
        await this[GET_PRICING]()
        this[CLEAR_FILES_TO_UPLOAD]()
        this[ACTION_SET_FIRST_ORDER_STEP]()
        this.configLoading = false
    },
    methods: {
        ...mapOrderMutations([
            CLEAR_FORM_STANDARD,
            LOADING_SET_STATUS,
            CLEAR_FILES_TO_UPLOAD,
            CLEAR_ALL_FILES
        ]),
        ...mapOrderActions([
            ACTION_EDIT_ORDER,
            ACTION_SET_FIRST_ORDER_STEP,
            ACTION_NEW_ORDER,
            ACTION_SET_ACTIVE_STEP
        ]),
        ...mapConfigActions([
            GET_PRICING
        ]),
        onSetNextStep(from) {
            this[ACTION_SET_ACTIVE_STEP]({ from, step: 1 })
            orderLogChangeSteps({
                key: from,
                value: 'Next',
                orderid: this.$route.params.orderid ?? '',
                session_id: this.form_data.session_id ?? ''
            })
        },
        onSetPreviousStep(from) {
            this[ACTION_SET_ACTIVE_STEP]({ from, step: -1 })
            orderLogChangeSteps({
                key: from,
                value: 'Previous',
                orderid: this.$route.params.orderid ?? '',
                session_id: this.form_data.session_id ?? ''
            })
        },
        scrollToBlock() {
            const firstErrorElement = document.querySelector(`#${this.getterActiveStep}`);
            const scrollTo = scroller()
            scrollTo(firstErrorElement, 500, { offset: -90 })
        },
        onEventValidateForm() {
            const current = this.$refs[this.getterActiveStep]
            current.validate()
        },
        async onEventClearForm() {
            const modalTitle = 'Reset Form'
            const modalText = 'You are about to reset the order form and start over. Are you sure?'
            if (await this.$root.$confirm(modalTitle, modalText, { color: 'red' })) {
                // we clear order.form_data object
                this[CLEAR_FORM_STANDARD]()
                eventBus.$emit('reset-form');
                eventBus.$emit('showSnackBar', 'Order form has been reset', 'info');
                this.$nextTick(() => this.$forceUpdate());
            }
        },
        async onEventSubmitOrder() {
            this[LOADING_SET_STATUS]({ message: 'Please wait...' })
            this.submitOrder = true
            try {
                const action = await this[ACTION_NEW_ORDER]({ orderid: this.$route.params.orderid })
                this.configLoading = false
                eventBus.$emit('showSnackBar', `${action === 'create' ? 'Order has been added to cart' : 'Order has been edited'}`, 'success')
                this.configLoading = true
                await this.$router.push({ name: 'checkout' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.submitOrder = false
                this[LOADING_SET_STATUS]({ message: '', status: false })
            }
        },
        onEventCalculating(val) {
            this.isCalculating = val
        }
    }
}
</script>
