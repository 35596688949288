<template>
    <div class="order_files">
        <header class="order_files__header">
            <div class="order_files__header-item order_files-col order_files-col--extension">
                Name
            </div>
            <div class="order_files__header-item order_files-col order_files-col--status">
                Status
            </div>
            <div class="order_files__header-item order_files-col order_files-col--size">
                Size
            </div>
            <div class="order_files__header-item order_files-col order_files-col--actions">
                Actions
            </div>
        </header>
        <main class="order_files__body">
            <div
                v-for="(file, index) in files"
                :key="index"
                class="order_files__item"
            >
                <div class="order_files-col order_files-col--extension">
                    <div class="loaded-file__preview">
                        <span class="loaded-file__preview-format">
                            {{ file.filetype ? file.filetype.split('/')[1] : file.type.split('/')[1] }}
                        </span>
                    </div>
                    <p>
                        {{ fileName(file) }}
                    </p>
                </div>
                <div
                    class="order_files-col order_files-col--status"
                    :class="{
                        'order_files-col--status--uploaded' :file.file_id
                    }"
                >
                    <span>{{ file.file_id ? 'Uploaded' : 'Attached' }}</span>
                </div>
                <div class="order_files-col order_files-col--size">
                    <p>{{ (Number(file.filesize) || Number(file.size)) | file_size }}</p>
                </div>
                <div class="order_files-col order_files-col--actions">
                    <button
                        class="order_files__item-delete button-action-background"
                        title="Delete File"
                        @click.prevent="deleteFile(file)"
                    />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filters-mixin'

export default {
    name: 'OrderFormFileTable',
    mixins: [
        filtersMixin
    ],
    props: {
        files: {
            type: Array,
            required: true
        }
    },
    methods: {
        deleteFile(file) {
            this.$emit('deleteFile', file)
        },
        fileName(file) {
            const name = file.filename || file.name
            const showDots = name.length >= 25
            return `${name.slice(0, 25)}${showDots ? '...' : ''}`
        }
    }
}
</script>

<style lang="scss">
    .order_files{
        font-size: 14px;
        width: 100%;
        &__header {
            display: flex;
            font-weight: 700;
            text-transform: uppercase;
            padding: 10px 5px;
            padding-top: 0;
            @media (max-width: 1200px) {
                display: none;
            }
        }
        &__item {
            display: flex;
            align-items: center;
            padding: 10px 5px;
            border-top: 1px solid rgba(0,0,0,0.2);
            border-bottom: 1px solid rgba(0,0,0,0.2);
            @media (max-width: 1200px) {
                flex-wrap: wrap;
            }
            &-delete {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border: none;
                transition: all 0.3s ease;
                background: transparent;
                cursor: pointer;
                &:before {
                    content: '';
                    display: block;
                    width: 14px;
                    height: 14px;
                    background: no-repeat center;
                    background-size: 100%;
                    background-image: url('~@/assets/img/svg/cross.svg');
                }
            }
        }
        &-col {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &--extension{
                display: flex;
                width: 40%;
                align-items: center;
                font-weight: 700;
                p {
                    width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    @media (max-width: 1200px) {
                        text-overflow: inherit;
                        overflow: inherit;
                    }
                }
                @media (max-width: 1200px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            &--size, &--actions{
                width: 20%;
                font-weight: 700;
                @include media1200max{
                    justify-content: flex-end;
                    width: 15%;
                }
            }
            &--actions {
                display: flex;
            }
            &--status{
                width: 20%;
                @include media1200max{
                    width: 70%;
                }
                span {
                    display: inline-block;
                    padding: 5px 10px;
                    background: #ebebeb;
                    color: $light-grey;
                    border-radius: 5px;
                }
                &--uploaded {
                    span {
                        background: #d4edda;
                        color: #219653;
                    }
                }
            }
        }
        &__alert{
            display: flex;
            text-align: left;
            margin-bottom: 15px;
            svg {
                fill: $main-color;
                margin-right: 10px;
            }
        }
    }
    .loaded-file {
        &__preview {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            margin-right: 20px;
            @include media1200max{
                margin-top: 30px;
            }

        }

        &__preview-format {
            display: block;
            width: 50px;
            height: 50px;
            padding-top: 28px;
            padding-left: 6px;
            color: #fff;
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
            background: url("~@/assets/img/svg/doc_empty.svg") no-repeat center;
            background-size: cover;
            text-align-last: left;
            transform: translateX(-2px);
        }

    }
</style>
