<template>
    <section class="fast-track-form">
        <div
            v-if="confirmationOrder"
            class="fast-track_confirmation"
        >
            Confirm project detail
        </div>
        <header
            v-if="showHeader"
            class="fast-track_header"
        >
            <p class="fast-track_header__title">
                Start by publishing your task
            </p>
            <p>
                Our experts will assess your content requirement and will get back with an offer.
            </p>
        </header>

        <main class="fast-track-body">
            <!-- Row -->
            <div class="fast-track-body_row bordered_row">
                <div class="fast-track-body_col fast-track-body_col--full">
                    <h2 class="fast-track-body__title">
                        Deadline &amp; budget
                    </h2>
                </div>
                <!-- Deadline -->
                <div class="fast-track-body_col">
                    <div class="input_wrapper">
                        <form-date-picker
                            v-model="$v.due_at.$model"
                            label="Deadline"
                            tabindex="0"
                            :min_hours="3"
                            :errors="validationMessage($v.due_at)"
                            :field.sync="due_at"
                            @change.native="$v.due_at.$touch()"
                            @blur.native="$v.due_at.$touch()"
                        />
                        <!-- <span class="custom-tooltip">
                            <p>?</p>
                            <span>
                                This should be set in your timezone.
                            </span>
                        </span> -->
                        <span class="fast-track_input-info">
                            <span class="fast-track_input-icon">i</span>
                            This should be set in your timezone.
                        </span>
                    </div>
                </div>
                <!-- desired_priced budget -->
                <div class="fast-track-body_col">
                    <div class="input_wrapper">
                        <form-input-type-number
                            v-model="$v.desired_price.$model"
                            label="Your approximate budget for the task (in US dollars)"
                            :field.sync="desired_price"
                            :show_value="true"
                            :with_text="true"
                            text="$"
                            tabindex="0"
                            :min_value="1"
                            :errors="validationMessage($v.desired_price)"
                            @reduce="reduceCount('desired_price')"
                            @increase="increaseCount('desired_price')"
                            @update:field="$v.desired_price.$touch()"
                        />
                        <!-- <span class="custom-tooltip">
                            <p>?</p>
                            <span>This information will help us find the most suitable expert for your task.</span>
                        </span> -->
                        <span class="fast-track_input-info">
                            <span class="fast-track_input-icon">i</span>
                            This information will help us find the most suitable expert for your task.
                        </span>
                    </div>
                </div>
            </div>
            <!-- Row -->
            <div class="fast-track-body_row">
                <div class="fast-track-body_col">
                    <div class="bordered_row">
                        <h2 class="fast-track-body__title">
                            Project details
                        </h2>
                        <!-- Title -->
                        <div class="input_wrapper">
                            <form-input
                                v-model="$v.title.$model"
                                legend="Name of project"
                                type="text"
                                name="title"
                                :info="'You can either provide a topic or have the writer come up with one'"
                                :field.sync="title"
                                :is-valid="$v.title.$dirty && !$v.title.$anyError"
                                :errors="validationMessage($v.title)"
                                @input.native="$v.title.$touch();"
                                @blur.native="$v.title.$touch();"
                            />
                        </div>
                        <!-- Instructions -->
                        <div class="input_wrapper input_wrapper--textarea">
                            <form-textarea
                                v-model="$v.details.$model"
                                legend="Details"
                                placeholder="Tell our experts about your task and how they can help"
                                type="text"
                                name="instructions"
                                :field.sync="details"
                                :errors="validationMessage($v.details)"
                                @input.native="$v.details.$touch();"
                                @blur.native="$v.details.$touch();"
                            />
                            <!-- <span class="custom-tooltip">
                                <p>?</p>
                                <span>
                                    The more detailed you are, the better. Our experts will base the proposal on your project description.
                                </span>
                            </span> -->
                            <span class="fast-track_input-info">
                                <span class="fast-track_input-icon">i</span>
                                The more detailed you are, the better. Our experts will base the proposal on your project description.
                            </span>
                        </div>
                    </div>
                    <!-- Additional services -->
                    <div class="">
                        <p class="fast-track-body__title">
                            What does your project consist of? Does it require illustrations, graphs, drawings or other things?
                        </p>
                        <!-- Pages -->
                        <div class="input_wrapper">
                            <form-input-type-number
                                v-model="$v.pages.$model"
                                label="Number of pages"
                                :field.sync="pages"
                                :show_value="true"
                                tabindex="0"
                                :min_value="1"
                                :errors="validationMessage($v.pages)"
                                @reduce="pages--"
                                @increase="pages++"
                                @update:field="$v.pages.$touch()"
                            />
                            <!-- <span class="custom-tooltip">
                                <p>?</p>
                                <span>All content is formatted as follows: 12 point Times New Roman font, 1 inch margins on all sides, double spacing, approx. 280 - 300 words per page.</span>
                            </span> -->
                            <span class="fast-track_input-info">
                                <span class="fast-track_input-icon">i</span>
                                All content is formatted as follows: 12 point Times New Roman font, 1 inch margins on all sides, double spacing, approx. 280 - 300 words per page.
                            </span>
                        </div>
                        <!-- slides -->
                        <div class="fast-track-body__switch-row">
                            <form-switch
                                id="showSlides"
                                v-model="showSlides"
                            />
                            <span class="fast-track-body__switch-text">
                                Presentation slides, drawings, illustrations.
                            </span>
                        </div>
                        <div
                            v-if="showSlides"
                            class="input_wrapper"
                        >
                            <form-input-type-number
                                v-model="$v.slides.$model"
                                label="Slides"
                                :field.sync="slides"
                                :show_value="true"
                                tabindex="0"
                                :min_value="1"
                                :errors="validationMessage($v.slides)"
                                @reduce="slides--"
                                @increase="slides++"
                                @update:field="$v.slides.$touch()"
                            />
                        </div>
                        <!-- Charts -->
                        <div class="fast-track-body__switch-row">
                            <form-switch
                                id="showCharts"
                                v-model="showCharts"
                            />
                            <span class="fast-track-body__switch-text">
                                Charts (tables, graphs, etc.)
                            </span>
                        </div>
                        <div
                            v-if="showCharts"
                            class="input_wrapper"
                        >
                            <form-input-type-number
                                v-model="$v.charts.$model"
                                label="Charts"
                                :field.sync="charts"
                                :show_value="true"
                                tabindex="0"
                                :min_value="1"
                                :errors="validationMessage($v.charts)"
                                @reduce="charts--"
                                @increase="charts++"
                                @update:field="$v.charts.$touch()"
                            />
                        </div>
                        <!-- Problems -->
                        <div class="fast-track-body__switch-row">
                            <form-switch
                                id="showProblems"
                                v-model="showProblems"
                            />
                            <span class="fast-track-body__switch-text">
                                Computational work (accounting, finance, statistics, etc.)
                            </span>
                        </div>
                        <div
                            v-if="showProblems"
                            class="input_wrapper"
                        >
                            <form-input-type-number
                                v-model="$v.problems.$model"
                                label="Problems"
                                :field.sync="problems"
                                :show_value="true"
                                tabindex="0"
                                :min_value="1"
                                :errors="validationMessage($v.problems)"
                                @reduce="problems--"
                                @increase="problems++"
                                @update:field="$v.problems.$touch()"
                            />
                        </div>
                    </div>
                </div>
                <!-- Additional files -->
                <div class="fast-track-body_col">
                    <h2 class="fast-track-body__title">
                        Upload additional information with instructions for your task
                    </h2>
                    <FormFilesUploader
                        class="input_wrapper"
                        :is-uploading-in-progress="isUploadingInProgress"
                        :upload-progress="uploadProgressValue"
                        @uploadFiles="uploadFiles"
                    />
                    <div
                        v-if="additional_files.length"
                        class="input_wrapper"
                    >
                        <OrderFormFileTable
                            :files="[...additional_files]"
                            @deleteFile="deleteFile"
                        />
                    </div>
                </div>
            </div>
        </main>
        <footer class="fast-track-footer">
            <custom-button
                :loading="orderCreating"
                :loading-message="loadingMessage"
                :disabled="orderCreating"
                default
                class="btn-page-location"
                @on-btn-click="validate"
            >
                Submit. No credit card is required
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M1 8H15M15 8L8 1M15 8L8 15"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </custom-button>
        </footer>
    </section>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus'
import { createOrder } from '@/services/fasttrack'
import { uploadFilesNoAuth, deleteFileNoAuth } from '@/services/files'

// validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/order/fast-track/rules-fast-track.js'
import { formMessages } from '@/validation/order/fast-track/messages-fast-track.js'
import formValidationMixin from '@/mixins/form-validation-mixin.js'

import { mapOrderFormFields } from '@/store/modules/order'

import OrderFormFileTable from '@/components/order/OrderFormFileTable';

import {
    CLEAR_FORM_EXPRESS
} from '@/store/modules/order/mutation-types'

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('order')

export default {
    name: 'FastTrackForm',
    components: {
        OrderFormFileTable
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    props: {
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            showCharts: false,
            showProblems: false,
            showSlides: false,
            orderCreating: false,
            loadingMessage: '',
            // Files
            isUploadingInProgress: false,
            uploadProgressValue: 0,
            confirmationOrder: false
        }
    },
    computed: {
        ...mapOrderFormFields([
            'express_form_data',
            'express_form_data.due_at',
            'express_form_data.desired_price',
            'express_form_data.service_type',
            'express_form_data.pages',
            'express_form_data.problems',
            'express_form_data.charts',
            'express_form_data.slides',
            'express_form_data.title',
            'express_form_data.details',
            'express_form_data.additional_files'
        ]),
        ...mapGetters('client', [
            'getterIsAuth'
        ])
    },
    watch: {
        showCharts(newV) {
            if (!!newV === false) {
                this.charts = null
            } else {
                this.charts = 1
            }
        },
        showProblems(newV) {
            if (!!newV === false) {
                this.problems = null
            } else {
                this.problems = 1
            }
        },
        showSlides(newV) {
            if (!!newV === false) {
                this.slides = null
            } else {
                this.slides = 1
            }
        }
    },
    validations: {
        ...validationRules
    },
    created() {
        this.showCharts = !!this.charts
        this.showProblems = !!this.problems
        this.showSlides = !!this.slides
        this.confirmationOrder = Boolean(localStorage.getItem('express-confirmation'))
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapOrderMutations([
            CLEAR_FORM_EXPRESS
        ]),
        async uploadFiles(filesArr) {
            try {
                this.isUploadingInProgress = true
                const files = await uploadFilesNoAuth(filesArr, null, (e) => {
                    this.uploadProgressValue = (Math.floor((e.loaded * 100) / e.total) - 1);
                })
                this.additional_files = [...this.additional_files, ...files]
                eventBus.$emit('showSnackBar', 'File was successfully uploaded', 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isUploadingInProgress = false
            }
        },
        async deleteFile(file) {
            try {
                await deleteFileNoAuth(file.file_id)
                this.additional_files = this.additional_files.filter(((item) => item.file_id !== file.file_id))
                eventBus.$emit('showSnackBar', `File "${file.filename}" has been deleted`, 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async submit() {
            this.$v.$reset()
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error')
                return
            }
            if (!this.getterIsAuth) {
                localStorage.setItem('login_last_location', window.location.href)
                localStorage.setItem('express-confirmation', new Date().toISOString())
                this.$router.push({ name: 'register' })
                return
            }
            try {
                this.orderCreating = true
                this.loadingMessage = 'Creating an order...'
                const orderId = await createOrder(this.express_form_data)
                this[CLEAR_FORM_EXPRESS]()
                this.$router.push({ name: 'order_details_express', params: { id: orderId } })
                localStorage.removeItem('express-confirmation')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.orderCreating = false
                this.loadingMessage = ''
            }
        },
        reduceCount(type) {
            this[type] -= 1
            this.$v[type].$touch()
        },
        increaseCount(type) {
            this[type] += 1
            this.$v[type].$touch()
        }
    }
}
</script>

<style lang="scss">
    .fast-track-form {
        background-color: #fff;
        box-shadow: 0px 8px 48px -10px rgba(16, 24, 40, 0.18);
        border-radius: 4px;
        margin-bottom: 40px;
    }
    .fast-track_header{
        background: linear-gradient(94.79deg, #2E579D 0.59%, #366BC9 98.53%);
        box-shadow: 0px 0px 4px 1px rgba(16, 24, 40, 0.05);
        color: #fff;
        padding: 30px 20px;
        text-align: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        &__title{
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 5px;
            @media all and (max-width: 992px) {
                font-size: 22px;
            }
        }
        &~.fast-track_confirmation {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
    .fast-track_confirmation{
        background: linear-gradient(94.79deg, #2ba125  0.59%, #27d91f 98.53%);
        // background: linear-gradient(94.79deg, #fef3c7  0.59%, #fef3c7 98.53%);
        box-shadow: 0px 0px 4px 1px rgba(16, 24, 40, 0.05);
        color: #fff;
        padding: 30px 20px;
        text-align: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-weight: 700;
        font-size: 26px;
        line-height: 36px;
        @media all and (max-width: 992px) {
            font-size: 22px;
        }
        &~.fast-track_header {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
    .fast-track-body{
        display: flex;
        flex-wrap: wrap;
        padding: 20px 10px;
        &_row {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        &_col {
            width: 50%;
            padding: 0 10px;
            &--full{
                width: 100%;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                padding: 0;
            }
        }
        &__title {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 20px;
        }
        .input_wrapper{
            position: relative;
            margin-bottom: 20px;
            &--textarea {
                textarea {
                    height: 122px;
                    min-height: 122px;
                    max-height: 122px;
                }
            }
        }
        &__switch-row{
            display: flex;
            margin-bottom: 20px;
        }
        &__switch-text {
            margin-left: 10px;
        }
        .bordered_row{
            border-bottom: 1px solid #EAEAEA;
            padding-bottom: 5px;
            margin-bottom: 20px;
        }
    }
    .fast-track-files{
        padding: 0;
        margin: 0;
    }
    .fast-track-footer {
        margin-top: 20px;
        padding: 20px 0;
        border-top: 1px solid #b3bbc6;
        @media all and (max-width: 768px) {
            margin-top: 0;
            border: none;
        }
        .btn-base {
            svg {
                margin-left: 15px;
                stroke: #fff;
                transition: .3s all;
            }
            &:hover {
                svg {
                    stroke: #4a5b76;
                }
            }
        }
    }
    .fast-track_input {
        &-info {
            display: inline-block;
            margin: 10px 0 0 10px;
            font-size: 14px;
        }
        &-icon {
            border: 2px solid $main-color;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 17px;
            transition: all 0.1s ease-in;
            color: $main-color;
            font-style: italic;
            font-weight: 700;
        }
    }
</style>
