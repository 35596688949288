<template>
    <div class="quote-sidebar">
        <div class="gradient-border" />

        <header class="quote-sidebar__header">
            <div class="row space-between">
                <h2 v-if="calculations.total">
                    Confirm & Go
                </h2>
                <h2 v-else>
                    Price Quote
                </h2>
                <div class="quote-sidebar__header-alert">
                    <span
                        class="clear"
                        @click="modalClearForm"
                    >
                        <DeleteOutline />
                        <span class="tool">
                            Clear form
                        </span>
                    </span>
                </div>
            </div>
        </header>

        <QuoteSidebarInitialMessage
            v-if="!isPriceAvailable || !due_at"
            :text="validationState.description.message"
        />

        <section v-else>
            <div class="quote-sidebar__data">
                <span class="quote-sidebar__data-name">Due date: </span>
                <span class="quote-sidebar__data-value">{{ due_at | moment_short_text }}</span>
            </div>
            <div class="quote-sidebar__data">
                <span class="quote-sidebar__data-name">Turnaround: </span>
                <span class="quote-sidebar__data-value">{{ calculations.timeframe_human_readable }}</span>
            </div>
            <div class="quote-sidebar__data">
                <span class="quote-sidebar__data-name">Project: </span>
                <span class="quote-sidebar__data-value">{{ validationState.description.project }}</span>
            </div>
            <div class="quote-sidebar__data">
                <span class="quote-sidebar__data-name">Level: </span>
                <span class="quote-sidebar__data-value">{{ validationState.description.level }}</span>
            </div>
            <div class="quote-sidebar__data">
                <form-switch
                    :id="'chk_discount'"
                    v-model="chk_discount"
                />
                <span class="order-form-step__switch-text">
                    I have a special discount code
                </span>
            </div>
            <div
                v-if="chk_discount"
                class="quote-sidebar__data"
            >
                <form-input
                    v-model="discountEntry"
                    legend="Discount code"
                    type="text"
                    name="discount_code"
                    :field.sync="discountEntry"
                    :is-valid="$v.discount_code.$dirty && !$v.discount_code.$anyError"
                    :errors="validationMessage($v.discount_code)"
                    @input.native="$v.discount_code.$touch();"
                    @blur.native="$v.discount_code.$touch();"
                />
            </div>
            <div
                v-if="isShowSidebarQuotation"
                class="quote-sidebar__quotation"
            >
                <!-- discount -->
                <div
                    v-if="calculations.discount_amount"
                    class="quote-sidebar__row space-between"
                >
                    <div>
                        <span v-if="calculations.discount_type === 'Code'">Code ({{ discount_code }})</span>
                        <span v-else>Loyalty discount</span>
                        {{ calculations.discount_rate | percent }}
                    </div>

                    <span
                        class="quote-sidebar__quotation-price"
                        style="color:green;"
                    >
                        - {{ calculations.discount_amount | money }}
                    </span>
                </div>
                <!-- discount -->
                <div
                    v-show="calculations.pages && calculations.pages.count > 0"
                    class="quote-sidebar__row space-between"
                >
                    <div>
                        <FileOutlineIcon class="quote-sidebar__icon" />
                        {{ calculations.pages.count }}
                        <span>{{ `${calculations.pages.count === 1 ? 'page' : 'pages'}` }}</span>
                        x {{ (calculations.pages.total / calculations.pages.count) | money }}
                    </div>
                    <span class="quote-sidebar__quotation-price">
                        {{ calculations.pages.total | money }}
                    </span>
                </div>
                <div
                    v-show="calculations.slides && calculations.slides.count > 0"
                    class="quote-sidebar__row space-between"
                >
                    <div>
                        <Paperclip class="quote-sidebar__icon" />
                        {{ calculations.slides.count }}
                        <span>{{ `${calculations.slides.count === 1 ? 'slide' : 'slides'}` }}</span>
                        x {{ (calculations.slides.total / calculations.slides.count) | money }}
                    </div>
                    <span class="quote-sidebar__quotation-price">
                        {{ calculations.slides.total | money }}
                    </span>
                </div>
                <div
                    v-show="calculations.charts && calculations.charts.count > 0"
                    class="quote-sidebar__row space-between"
                >
                    <div>
                        <ImageOutlineIcon class="quote-sidebar__icon" />
                        {{ calculations.charts.count }}
                        <span>{{ `${calculations.charts.count === 1 ? 'chart' : 'charts'}` }}</span>
                        x {{ (calculations.charts.total / calculations.charts.count) | money }}
                    </div>
                    <span class="quote-sidebar__quotation-price">
                        {{ calculations.charts.total | money }}
                    </span>
                </div>
                <div
                    v-show="calculations.problems && calculations.problems.count > 0"
                    class="quote-sidebar__row space-between"
                >
                    <div>
                        <AlertOutlineIcon class="quote-sidebar__icon" />
                        {{ calculations.problems.count }}
                        <span>{{ `${calculations.problems.count === 1 ? 'problem' : 'problems'}` }}</span>
                        x {{ (calculations.problems.total / calculations.problems.count) | money }}
                    </div>
                    <span class="quote-sidebar__quotation-price">
                        {{ calculations.problems.total | money }}
                    </span>
                </div>
                <!-- preferred writer surcharge -->
                <div
                    v-show="calculations.preferred_writers.count > 0"
                    class="quote-sidebar__row space-between"
                >
                    <div>
                        <Writers class="quote-sidebar__icon quote-sidebar__icon--without-count" />
                        Preferred writer (+{{ calculations.preferred_writers.rate | percent }})
                    </div>
                    <span class="quote-sidebar__quotation-price">
                        + {{ calculations.preferred_writers.total | money }}
                    </span>
                </div>
                <!-- preferred writer surcharge -->
                <div
                    v-show="calculations.chk_abstract.required"
                    class="quote-sidebar__row space-between"
                >
                    <div>
                        <Check class="quote-sidebar__icon quote-sidebar__icon--without-count" />
                        Abstract
                    </div>
                    <span class="quote-sidebar__quotation-price">
                        {{ calculations.chk_abstract.total | money }}
                    </span>
                </div>
                <!-- preferred writer surcharge -->
                <div
                    v-show="calculations.chk_outline.required"
                    class="quote-sidebar__row space-between"
                >
                    <div>
                        <Check class="quote-sidebar__icon quote-sidebar__icon--without-count" />
                        Outline
                    </div>
                    <span class="quote-sidebar__quotation-price">
                        {{ calculations.chk_outline.total | money }}
                    </span>
                </div>
                <!-- preferred writer surcharge -->
            </div>

            <div class="quote-sidebar__row space-between">
                <span>Total price:</span>
                <animated-number
                    class="quote-sidebar__total-amount"
                    :value="calculations.total"
                    :duration="300"
                    :format-value="$options.filters.money"
                />
            </div>

            <div class="quote-sidebar__row">
                <custom-button
                    id="btnAddToCart"
                    :class="isSubmitAllowed ? `btn-base btn-main quote-sidebar__btn` : `btn-base btn-disabled quote-sidebar__btn`"
                    :disabled="!isSubmitAllowed || isCalculating || getterLoadingStatus.status"
                    :loading="isCalculating || submitOrder || getterLoadingStatus.status"
                    :loading-message="(isCalculating || getterLoadingStatus.status) ? getterLoadingStatus.status ? getterLoadingStatus.message : 'Calculating...' : ''"
                    :is-calculating-animate="true"
                    :upload-progress="getterUploadProgressValue"
                    @on-btn-click="submit"
                >
                    {{ isEditPage ? 'Save changes' : 'Add to cart' }}
                </custom-button>
            </div>

            <!-- mobile total -->
            <!-- <div
                :class="mobileTotal && 'total-mobile--active'"
                class="total-mobile"
            >
                <span class="total-price">{{ calculations.total | money }}</span>
            </div> -->
            <!-- mobile total -->
        </section>
    </div>
</template>

<script>
import { debounce, cloneDeep } from 'lodash'
// icons
import FileOutline from 'mdi-vue/FileOutline.vue'
import ImageOutline from 'mdi-vue/ImageOutline.vue'
import AlertOutline from 'mdi-vue/AlertOutline.vue'
import DeleteOutline from 'mdi-vue/DeleteOutline.vue'
import Paperclip from 'mdi-vue/Paperclip';

import Check from '@/components/icons/Check'
import Writers from '@/components/icons/Writers'

import AnimatedNumber from 'animated-number-vue'

// vuex bindings
import { mapGetters, createNamespacedHelpers } from 'vuex'
import formValidationMixin from '@/mixins/form-validation-mixin.js'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/order/rules-quote-sidebar'
import { formMessages } from '@/validation/order/messages-quote-sidebar'

// lodash
import filtersMixin from '@/mixins/filters-mixin.js'

// calculations Class
import { CalculatorValidator } from '@/services/calculator/index.js'

// VUEX
import {
    ACTION_VALIDATE_DISCOUNT,
    ACTION_CALCULATE_ORDER
} from '@/store/modules/order/action-types.js'

import { mapOrderFormFields } from '@/store/modules/order'

// components
import QuoteSidebarInitialMessage from './QuoteSidebarInitialMessage.vue'

const {
    mapActions: mapOrderActions
} = createNamespacedHelpers('order')

export default {
    name: 'QuoteSidebar',
    components: {
        QuoteSidebarInitialMessage,
        FileOutlineIcon: FileOutline,
        ImageOutlineIcon: ImageOutline,
        AlertOutlineIcon: AlertOutline,
        AnimatedNumber,
        DeleteOutline,
        Paperclip,
        Check,
        Writers
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        filtersMixin
    ],
    validations: {
        ...validationRules
    },
    props: {
        isEditPage: {
            type: Boolean,
            required: true
        },
        submitOrder: {
            type: Boolean
        }
    },
    data() {
        return {
            discountEntry: '',
            mobileTotal: false,
            calculations: {},
            validationState: {},
            isCalculating: true
        }
    },
    computed: {
        ...mapOrderFormFields([
            'form_data.type',
            'form_data.academiclevel',
            'form_data.pages',
            'form_data.due_at',
            'form_data.slides',
            'form_data.sources',
            'form_data.style',
            'form_data.charts',
            'form_data.problems',
            'form_data.preferred_writers',
            'form_data.discount_code',
            'form_data.chk_outline',
            'form_data.chk_abstract',
            'form_data.chk_discount'
        ]),
        ...mapGetters('order', [
            'getOrderFormData',
            'getterLoadingStatus',
            'getterActiveStep',
            'getterUploadProgressValue'
        ]),
        keysAffectPrice() {
            const {
                due_at,
                type,
                academiclevel,
                pages,
                problems,
                charts,
                slides,
                preferred_writers,
                discount_code,
                chk_outline,
                chk_abstract
            } = this
            const preferredWritersIds = preferred_writers.map(({ id }) => id)
            return {
                due_at, type, academiclevel, pages, problems, charts, slides, preferred_writers: preferredWritersIds, discount_code, chk_outline, chk_abstract
            }
        },
        isPriceAvailable() {
            // we check the dataset received from calculator
            return this.validationState.description.message === ''
        },
        isSubmitAllowed() {
            return this.getterActiveStep === 'ExtrasServices'
        },
        isShowSidebarQuotation() {
            return this.calculations.pages?.count > 0 || this.calculations.charts?.count > 0 || this.calculations.slides?.count > 0 || this.calculations.problems?.count > 0
        }
    },
    watch: {
        'calculations.total': function () {
            clearTimeout(this.timer)
            this.mobileTotal = true
            this.timer = setTimeout(() => {
                this.mobileTotal = false
            }, 7000)
        },
        keysAffectPrice: {
            async handler() {
                await this.getOrderTotal()
                this.validateOrderForm()
            },
            deep: true
        },
        isCalculating(val) {
            this.$emit('eventCalculating', val)
        },
        discountEntry: debounce(function (value) {
            this.discount_code = value
        }, 1000),
        chk_discount(val) {
            if (!val) {
                this.discountEntry = '';
                this.discount_code = '';
            }
        }
    },
    async created() {
        this.discountEntry = this.discount_code
        if (this.discount_code) {
            this.$v.discount_code.$touch()
        }
        this.validateOrderForm()
        await this.getOrderTotal()
    },
    methods: {
        ...mapOrderActions({
            ACTION_CALCULATE_ORDER,
            validateDiscount: ACTION_VALIDATE_DISCOUNT
        }),
        validationMessage: validationMessage(formMessages),
        modalClearForm() {
            this.$emit('eventClearForm')
        },
        submit() {
            if (this.getterActiveStep !== 'ExtrasServices') {
                this.$emit('eventValidateForm')
                return
            }
            this.$emit('eventSubmitOrder')
        },
        openModal() {
            return this.$root.$report({
                title: 'Clear Form',
                description: 'Are you sure you want to delete all data from the order form?'
            })
        },
        getOrderTotal() {
            this.isCalculating = true
            if (typeof window.LIT !== 'undefined') {
                clearTimeout(window.LIT);
            }
            // required fields
            if (this.due_at && this.type && this.academiclevel) {
                window.LIT = setTimeout(async () => {
                    await this.ACTION_CALCULATE_ORDER(this.keysAffectPrice)
                        .then((resp) => {
                            this.calculations = resp
                            this.isCalculating = false
                        })
                }, 500);
            }
        },
        validateOrderForm() {
            const instance = new CalculatorValidator(
                cloneDeep(this.$store.state.config.pricing),
                cloneDeep(this.getOrderFormData)
            )
            this.validationState = instance.getData()
        }
    }
}
</script>
