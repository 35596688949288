<template>
    <div class="account-header">
        <div class="account-header_data">
            <avatar
                class="account-header_avatar"
                :name="getInitial"
            />
            <div class="account-header_text">
                <p class="account-header_text__title">
                    Welcome, {{ getClientName }}!
                </p>
                <div class="account-header_balances">
                    <p class="account-header_balances__item">
                        <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.55 2.22 12.05 2.59 12.42L11.59 21.42C11.95 21.78 12.45 22 13 22C13.55 22 14.05 21.78 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.45 21.77 11.94 21.41 11.58ZM13 20.01L4 11V4H11V3.99L20 12.99L13 20.01Z"
                            />
                            <path
                                d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z"
                            />
                            <path
                                d="M8.8999 12.55C8.8999 13.12 9.1299 13.62 9.4999 14L12.9999 17.5L16.4999 14C16.8544 13.6096 17.045 13.0977 17.0323 12.5705C17.0195 12.0433 16.8044 11.5412 16.4316 11.1683C16.0587 10.7955 15.5566 10.5804 15.0294 10.5676C14.5022 10.5549 13.9903 10.7455 13.5999 11.1L12.9999 11.7L12.3999 11.11C12.0299 10.73 11.5099 10.5 10.9499 10.5C9.8199 10.5 8.8999 11.42 8.8999 12.55Z"
                            />
                        </svg>

                        <b> {{ getterMainClientInfo.loaylty_discount | percent }}</b>
                        Loyalty discount
                    </p>
                    <p class="account-header_balances__item">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path d="M452 78.668h-18.668V60c0-33.086-26.914-60-60-60h-314C26.617 0 0 26.617 0 59.332V449c0 34.738 28.262 63 63 63h389c33.086 0 60-26.914 60-60V138.668c0-33.086-26.914-60-60-60zm20 255.664h-78.332c-21.508 0-39-17.492-39-39 0-21.504 17.492-39 39-39H472zM59.332 40h314c11.027 0 20 8.973 20 20v18.668h-334C48.672 78.668 40 69.992 40 59.332 40 48.672 48.672 40 59.332 40zM452 472H63c-12.684 0-23-10.316-23-23V115.434a59.112 59.112 0 0 0 19.332 3.23H452c11.027 0 20 8.973 20 20v77.668h-78.332c-43.563 0-79 35.441-79 79 0 43.563 35.437 79 79 79H472V452c0 11.027-8.973 20-20 20zm0 0" />
                        </svg>

                        <b> {{ getterMainClientInfo.account_balance | money }}</b>
                        Credits
                    </p>
                </div>
            </div>
        </div>
        <div class="account-header_buttons">
            <button
                v-if="!$route.path.includes('/order')"
                ref="orderBtn"
                type="button"
                class="btn-base btn-main cart-btn-empty cart-btn-animated"
                @click="$router.push({ name: 'order' })"
            >
                <div
                    class="ink animate"
                    style="height: 200px;width: 250px;top: -20px;left: -140px;"
                />
                <span class="cart-btn-empty__img">
                    <order-new />
                </span>
                <span class="cart-btn-order-text">{{ $t('components.order_cart_button.text') }}</span>
            </button>
            <button
                class="btn-base btn-main"
                @click="showTopUp = true"
            >
                <WalletPlusOutline />
                <span>
                    top up balance
                </span>
            </button>
        </div>

        <TopUpModal
            v-if="showTopUp"
            @closeTopUp="showTopUp = false"
        />
        <tooltip-lg
            v-if="showOrderTooltip"
            :target="orderBtn"
            placement="top"
            closable
            animate
        >
            <div class="flex flex-col gap-4">
                <p><strong>👋 Start here!</strong></p>
                <p>Press button <strong> &laquo;NEW ORDER&raquo;</strong> to create your first order.</p>
            </div>
        </tooltip-lg>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import filtersMixin from '@/mixins/filters-mixin.js'

import Avatar from '@/components/common/Avatar'
import WalletPlusOutline from 'mdi-vue/WalletPlusOutline'
import TopUpModal from '@/components/account/TopUpModal';
import OrderNew from '@/components/icons/OrderNew';
import TooltipLg from '@/components/TooltipLg.vue'
import Helper from '@/helpers/functions'

export default {
    name: 'AccountTopBar',
    components: {
        WalletPlusOutline,
        Avatar,
        TopUpModal,
        OrderNew,
        TooltipLg
    },
    mixins: [
        filtersMixin
    ],
    data() {
        return {
            showTopUp: false,
            orderBtn: null,
            showOrderTooltip: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo'
        ]),
        getInitial() {
            const { firstname, lastname } = this.getterMainClientInfo
            if (!!firstname && !!lastname) {
                return `${firstname[0]}${lastname[0]}`
            }
            return null
        },
        getClientName() {
            const { firstname, id } = this.getterMainClientInfo
            if (firstname) {
                return firstname.charAt(0).toUpperCase() + firstname.slice(1)
            }
            return `User ${id}`
        }
    },
    watch: {
        showTopUp(val) {
            Helper.lockBody(val)
        }
    },
    mounted() {
        this.orderBtn = this.$refs.orderBtn
        this.showOrderTooltip = !this.getterMainClientInfo.order_total && !this.$route.path.includes('/order')
    }
}
</script>

<style lang="scss">
    .account-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 576px) {
            flex-direction: column;
        }
        &_data{
            display: flex;
            align-items: center;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        &_avatar{
            width: 120px;
            height: 120px;
            margin-right: 20px;
            .avatar-img{
                width: 100%;
                height: 100%;
                .initials {
                    font-weight: 700;
                    font-size: 32px;
                    @media all and (max-width: 768px) {
                        font-size: 24px;
                    }
                }
            }
            @media all and (max-width: 768px) {
                width: 72px;
                height: 72px;
            }
        }
        &_text {
            &__title{
                font-size: 32px;
                line-height: 45px;
                font-weight: 700;
                color: #111720;
                margin-bottom: 10px;
                @media all and (max-width: 768px) {
                    font-size: 26px;
                    line-height: 32px;
                }
            }
        }
        &_balances{
            display: flex;
            @media all and (max-width: 768px) {
                flex-direction: column;
            }
            &__item {
                display: flex;
                margin-right: 30px;
                color: #61646A;
                @media all and (max-width: 768px) {
                    margin-right: 0;
                    &:first-child {
                        margin-bottom: 10px;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                svg {
                    width: 20px;
                    fill: #111720;
                    margin-right: 10px;
                }
                & > b {
                    color: #111720;
                    margin-right: 5px;
                }
            }
        }
        &_buttons{
            @media all and (max-width: 576px) {
                width: 100%;
                margin-top: 30px;
                .btn-base {
                    width: 100%;
                }
            }
            .cart-btn-animated {
                display: none;
                @media (max-width:576px) {
                    margin-left: 0;
                    display: flex;
                    margin-bottom: 15px;
                    margin-right: 0;
                }
            }
            .mdi  {
                margin-right: 12px;
            }
        }
    }
</style>
